<template>
  <section class="hero">
    <div class="hero-body">
      <div class="console-outer">
        <p v-for="log in $store.state.debugLog" :key="log.index">
          {{ new Date(log.timestamp).toLocaleTimeString() }} {{ log.content }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.console-outer {
  height: 408px;
  max-height: 3060px;
  width: 100%;
  max-width: 100%;
  background-color: #131a20;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 5px;
}

p {
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-size: 0.7em;
  padding: 0;
}
</style>
